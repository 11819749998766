import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../Header";

export const Layout = () => {

  return (
    <>
      <CssBaseline />
      <Header />
      <Box>
        <Outlet />
      </Box>
    </>
  );
};
