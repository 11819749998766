import { Link } from "react-router-dom";
import { LoginContext } from "../context/loginContext";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Toolbar, Divider, List, ListItem, AppBar } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { UserAgentApplication } from "msal";
import AppsIcon from "@material-ui/icons/Apps";
import clsx from "clsx";
import config from "../config/config";
import logo from "../statics/falafhd.png";
import React, { useContext, useState, useEffect } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import keycloakService from "../services/keycloakService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

const Header = ({ inRegister }) => {
  // const { loginStateCon, setLoginStateCon } = useContext(LoginContext);
  // const { isAuthenticated } = loginStateCon;
  const { doLogout, isLoggedIn, getFullName } = keycloakService;
  const classes = useStyles();
  const isAdmin = true;
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  });

  const [loginState, setLoginState] = useState({
    isAuthenticated: false,
    user: {},
    token: null,
    azureAd: false,
    permission: "",
    error: null,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>Administración</ListItem>
        <ListItem></ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to={{
            pathname: "/",
            //loginState: loginState,
          }}
        >
          Home
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to={{
            pathname: "/admin/folios",
            loginState: loginState,
          }}
        >
          Folios
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/admin/documents">
          Documentos DTE
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/admin/caff">
          CAFF
        </ListItem>
        <Divider />

        <ListItem button component={Link} to="/cesiones/carga-documentos">
          Cesiones Carga Documentos
        </ListItem>
        <Divider />

        <ListItem button component={Link} to="/cesiones/empresas-emisoras">
          Cesiones Empresas Emisoras
        </ListItem>
        <Divider />

        <ListItem button component={Link} to="/cesiones/empresas-factoring">
          Cesiones Empresas Factoring
        </ListItem>
        <Divider />

      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
          {isAdmin && isLoggedIn ? (
            <div style={{ marginRight: 30 }}>
              <AppsIcon style={{ fontSize: 40 }} onClick={toggleDrawer("left", true)} />
              <SwipeableDrawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
                onOpen={toggleDrawer("left", true)}
              >
                {list("left")}
              </SwipeableDrawer>
            </div>
          ) : null}

          <img src={logo} width="200" height="100" />
          {isLoggedIn ? (
            <Typography className={classes.title}>Bienvenido {getFullName()}</Typography>
          ) : null}
          {isLoggedIn ? (
            <button onClick={() => doLogout()} style={{ cursor: "pointer" }}>
              Cerrar Sesión
            </button>
          ) : null}
          {inRegister ? <Link to="/">volver</Link> : null}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
