import { Outlet } from "react-router-dom";
import keycloakService from "../../services/keycloakService";

const PrivateRoutes = () => {
  const { isLoggedIn } = keycloakService;

  return isLoggedIn() ? <Outlet /> : <div />;
};

export default PrivateRoutes;
