import { lazyLoadRoutes } from "./lazyLoadRoutes";

export const routes = [
  {
    path: "/admin/documents",
    exact: true,
    component: lazyLoadRoutes("components/adminDocuments"),
  },
  {
    path: "/admin/folios",
    exact: true,
    component: lazyLoadRoutes("components/adminFolios"),
  },
  {
    path: "/admin/caff",
    exact: true,
    component: lazyLoadRoutes("views/FoliosAdd"),
  },
  {
    path: "/admin/caff/list",
    exact: true,
    component: lazyLoadRoutes("views/FoliosList"),
  },
  {
    path: "/register",
    exact: true,
    component: lazyLoadRoutes("components/register"),
  },
  {
    path: "/forgotPassword", // TODO: quitar camelCase
    exact: true,
    component: lazyLoadRoutes("components/forgotPassword"),
  },
  {
    path: "/resetPassword/:slug", // TODO: quitar camelCase
    exact: true,
    component: lazyLoadRoutes("components/resetPassword"),
  },
  {
    path: "/cesiones/carga-documentos",
    exact: true,
    component: lazyLoadRoutes("components/cesiones/cargaDocumentos"),
  },
  {
    path: "/cesiones/empresas-emisoras",
    exact: true,
    component: lazyLoadRoutes("components/cesiones/empresasEmisoras"),
  },
  {
    path: "/cesiones/empresas-factoring",
    exact: true,
    component: lazyLoadRoutes("components/cesiones/empresasFactoring"),
  }
];

/* export const publictRoutes = [
  {
    path: ["/", "/login"],
    exact: true,
    component: "components/azureAD",
  },
  {
    path: ["/register"],
    exact: true,
    component: "components/register",
  },
  {
    path: ["/forgotPassword"], // TODO: quitar camelCase
    exact: true,
    component: "components/forgotPassword",
  },
  {
    path: ["/resetPassword/:slug"], // TODO: quitar camelCase
    exact: true,
    component: "components/resetPassword",
  },
  {
    path: ["/authentication"],
    exact: true,
    component: "components/authentication",
  },
]; */
