import { lazy, Suspense } from "react";
import { LoadingProgress } from "../components/utils/LoadingProgress";

export const lazyLoadRoutes = (componentName) => {
  const LazyElement = lazy(() => import(`../${componentName}`));

  return (
    <Suspense fallback={<LoadingProgress />}>
      <LazyElement />
    </Suspense>
  );
};
