import "./App.css";
import { Layout } from "./components/views/Layout";
import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "./router/config";
import PrivateRoutes from "./components/utils/PrivateRoutes";
import React from "react";
import LoginProvider from "./context/loginContext";

const App = () => {
  return (
    <div className="App">
    <LoginProvider>


      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Layout />}>
            {routes.map(({ path, exact, component }, index) => {
              return (
                <Route
                  key={index}
                  path={path}
                  exact={exact}
                  element={component}
                />
              );
            })}
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      </LoginProvider>
    </div>
  );
};

export default App;
