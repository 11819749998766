import React, { createContext, useState, useEffect } from "react";
import keycloakService from "../services/keycloakService";

//creando el context
export const LoginContext = createContext();

//creando provider, es donde se encuentran las funciones y el state
const LoginProvider = ({ children }) => {

  const { getToken, getTokenParsed, isLoggedIn } = keycloakService;

  const [loginStateCon, setLoginStateCon] = useState({
    isAuthenticated: false,
    user: {},
    token: null,
    azureAd: false,
    permission: "",
    isAdmin: false,
    error: null,
  });

  useEffect(() => {
    
    if (isLoggedIn()) {
      
      const user = getTokenParsed()
      
      setLoginStateCon({
        isAuthenticated: true,
        user: {
          displayName: user.given_name + " " + user.family_name,
          user_national_id: "", //user.user_national_id,
          user_email: user.email,
        },
        isAdmin: true,
        error: "",
      });
    }
  }, [getToken, getTokenParsed, isLoggedIn]);

  return (
    <LoginContext.Provider
      value={{
        loginStateCon,
        setLoginStateCon,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
