import Keycloak from "keycloak-js";
const keycloak = new Keycloak("/keycloak.json");

const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    })
    .catch(console.error);
};

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => "Bearer " + keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback) =>
  keycloak.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => keycloak.tokenParsed?.family_name;

const getTokenParsed = () => keycloak.tokenParsed;

const getFullName = () => {
  const tokenParsed = keycloak.tokenParsed;
  return `${tokenParsed?.given_name} ${tokenParsed?.family_name}`;
};

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getFullName,
  getTokenParsed,
};

export default KeycloakService;
